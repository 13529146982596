<template>
  <div class="page">
    <div class="w-90 h-full md:pb-44">
      <div class="relative z-10 space-y-12 mt-10">
        <div class="media-list">
          <div v-if="page == 1 && isLoadingPage" class="media-loader-wrap">
            <ProgressSpinner/>
          </div>

          <div v-else class="media-grid-wrapper">
            <div class="media-grid ">
              <h1>here</h1>
              <h1 style="font-size:20px; font-weight:bold;margin-bottom:20px;margin-top:10px">Үзсэн түүхийн
                жагсаалт</h1>
              <router-link :to="`/watch/${item.anime_id}/${item.id}`" v-for="item in mediaList" :key="item.id"
                           class="media-item">
                <div class="last-episode">
                  <div class="last-episode-image-section">
                    <img
                        :src="Poster"
                        v-lazy:background-image="imageUrl(item.image, true)"
                        class="last-episode-img"
                    />
                    <div style="color:black;height:100px; width:2px"/>
                  </div>
                  <div class="last-episode-content">
                    <div class="last-episode-content-title">
                      <p style="font-size:14px; font-weight:bold">{{ item.title }}</p>
                      <i style="margin-left:10px" class="isax isax-timer-1"></i>
                    </div>
                    <div class="last-episode-content-class">
                      <i style="margin-left:10px" class="isax isax-video-play"></i>
                      <p style="font-size:14px;margin-left:10px">{{ item.number }} анги</p>
                    </div>
                    <div class="last-episode-content-about">
                      <!--                      <p>Товч тайлбар:</p>-->
                      <p style="font-size:14px;margin-left:10px; text-align:justify" v-html="item.adesc"/>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="hasMore && !isLoadingPage" class="pagination">
          <ProgressSpinner v-if="isLoadingMore" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
          <Button v-else type="button" @click="getEpisodes">
            <span>Цааш</span>
            <i class="pi pi-angle-double-down"></i>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.last-episode {
  display: flex;
  flex-direction: row;
  /*align-items:center;*/
  justify-content: center
}

.last-episode-image-section {
  width: 54px;
  height: 54px;
  border-radius: 100px;
  background-color: grey;
  /*padding: 10px 10px 10px 10px;*/
}

.last-episode-img {
  width: 54px;
  height: 54px;
  border-radius: 100px
}

.last-episode-content {
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 10px;
  width: 500px;
  margin-left: 30px;
  background-color: white;
  padding: 8px 10px 8px 10px
}

.last-episode-content-title {
  display: flex;
  flex-diretion: row;
  justify-content: space-between;
  padding: 8px 10px 8px 10px
}

.last-episode-content-class {
  display: flex;
  flex-diretion: row;
  align-items: center
}

.last-episode-content-about {
  padding-top: 8px
}

</style>
<script>

import Poster from "../../assets/poster.png";
import {imageUrl} from "../../utils";
import {setModalActive} from "../../store";
import useDevice from "../../hooks/useDevice";

export default {
  setup() {
    const {isDesktop} = useDevice();
    return {isDesktop};
  },

  data() {
    return {
      isLoadingPage: true,
      isLoadingMore: false,
      cat: null,
      hasMore: false,
      page: 1,
      Poster
    }
  },

  computed: {
    mediaList() {
      return this.$store.state['latestEpisodes'];
    }
  },

  created() {
    this.getEpisodes();
  },

  methods: {
    imageUrl,
    handleClick(animeID) {
      this.$router.replace({query: {media: animeID}});
      setModalActive(true);
    },

    getEpisodes() {
      this.isLoadingMore = true;
      console.log(this.page);

      this.$store.dispatch('getLatestEpisodes', {vm: this});
    }
  }
};
</script>